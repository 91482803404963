main {
    min-height: 80vh;
}

.testing {
/*    background-image: url('assets/DSC_0151.jpg');
*/    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.homeCenter {
    font-size: 15rem;
    color: rgb(37, 37, 36);
}